import React, { useEffect, useState, useContext } from 'react';
import { matchPath, useLocation, useParams, useHistory } from 'react-router-dom';
import Context, { InstallationContext } from '../../context';
import QuestionsSequence from '../QuestionsSequence';
import { get, getEmployeesDataFromInstallation, post } from '../../helpers/fetch';
import InstallationTaskView from '../../components/InstallationTask';
import SubHeader from '../../components/SubHeader';
import QuestionsList from '../QuestionsList';
import { useTranslation } from 'react-i18next';
import NetworkDetailsModal from '../NetworkDetailsModal';
import { addHandoverStep, getQuestionSetDescription } from '../../helpers/question';
import NavigationStepper, { NavigationStep } from '../../components/NavigationStepper';
import CustomizedProgressBar from '../../components/CustomizedProgressBar';
import { chunk } from 'lodash';
import {
  Installation,
  ActivityDifferentiator,
  AnswerSet,
  Answer,
  FormId,
  QuestionSet,
  InstallationStatus,
} from '../../schemas';
import { useGetUserData } from '../../hooks/useGetUserData';
import { useGetCurrentUserRole } from '../../hooks/useGetCurrentUserRole';
import {
  getInstallationStatusIndex,
  installationStatuses,
} from '../../helpers/getInstallationLists';
import ProgressView from '../ProgressView';
import DeviationsView from '../DeviationsView';
import Empty from '../../components/Empty';
import InstallationStarting from '../InstallationStarting';

import {
  generateIndexedDBKey,
  getIndexedDBObject,
  storedIndexedDBObjectType,
} from '../../helpers/indexedDB';
import { cacheNetworkWithInstallationData } from '../../helpers/syncHelper';
import { cacheDeviationsData } from '../../helpers/deviationOffline';
import { extractImageLink } from '../../helpers/tip-images-helper';
import {
  getKoneEmployeeUserRole,
  getSubcontractorUserRole,
} from '../../helpers/getUserRole';
import { useGetToken } from '../../hooks/useGetToken';
import { useIfSubcontractor } from '../../hooks/useIfSubcontractor';
import { getDeviationsData } from '../../helpers/deviationActions';
import { InstallationActionName } from '../../reducers/installation';
import FloatingSyncButton from '../../components/FloatingSyncButton';
import PreInstallationChecklist from '../../components/Pre-Checklist';

export type QueryParams = {
  networkNumber: string;
};

export enum NetworkInstallationPaths {
  DEVIATIONS = '/:networkNumber/deviations',
  EXECUTION = '/:networkNumber/execution',
  MYDAY = '/:networkNumber/myday',
  MYPLAN = '/:networkNumber/myplan',
  PROGRESS = '/:networkNumber/progress',
  STARTING = '/:networkNumber/starting',
  PREINSTALLCHECKLIST = '/:networkNumber/starting/pre-install-checklist',
}

export enum SubcontractorNetworkInstallationPaths {
  DEVIATIONS = '/subcontractor/:networkNumber/deviations',
  EXECUTION = '/subcontractor/:networkNumber/execution',
  MYDAY = '/subcontractor/:networkNumber/myday',
  STARTING = '/subcontractor/:networkNumber/starting',
  PREINSTALLCHECKLIST = '/subcontractor/:networkNumber/starting/pre-install-checklist',
}

const SUPPORTED_URLs = [
  ...Object.values(NetworkInstallationPaths),
  ...Object.values(SubcontractorNetworkInstallationPaths),
];

export type NetworkInstallationProp = {
  redirectToReferrerUrl?: () => Promise<void>;
};

type ConvertedAnswer = {
  answer: Answer | null;
  questionSetId: string;
  questionNumberSequence: number;
};

const SCREEN_HEIGHT = '100vh';
const HEADER_AND_STEPPER_COMBINED_HEIGHT = '144px';
const FOOTER_HEIGHT = '86px';
export const CONTAINER_HEIGHT = `calc(${SCREEN_HEIGHT} - ${HEADER_AND_STEPPER_COMBINED_HEIGHT} - ${FOOTER_HEIGHT})`;

const getQuestionNextToFurthestAnsweredQuestion = (
  answers: AnswerSet[]
): { questionSetId: string; questionNumberSequence: number } | null => {
  //flatten an array of answer sets to an array of answers
  const allAnswers = answers.reduce(
    (accumulate: ConvertedAnswer[], answerSet: AnswerSet) => {
      const convertedAnswers = answerSet.answers.map((answer, answerIndex) => {
        return {
          answer,
          questionSetId: answerSet.questionSetId,
          questionNumberSequence: answerIndex,
        };
      });

      accumulate = [...accumulate, ...convertedAnswers];
      return accumulate;
    },
    []
  );

  if (allAnswers.length > 0) {
    for (let i = allAnswers.length - 1; i >= 0; i--) {
      if ((allAnswers[i].answer && allAnswers[i].answer?.value !== null) || i === 0) {
        if ([allAnswers.length - 1, 0].includes(i)) {
          // if user answered last question or haven't answered any question at all
          return {
            questionSetId: allAnswers[i].questionSetId,
            questionNumberSequence: allAnswers[i].questionNumberSequence,
          };
        } else {
          return {
            questionSetId: allAnswers[i + 1].questionSetId,
            questionNumberSequence: allAnswers[i + 1].questionNumberSequence,
          };
        }
      }
    }
  }

  return null;
};

const getIfCachingNeeded = (
  installationStatus: InstallationStatus | null,
  userRole?: ActivityDifferentiator
): boolean => {
  if (!installationStatus || !userRole) return false;

  const shouldCacheForInstaller =
    userRole === ActivityDifferentiator.INST &&
    [
      InstallationStatus.INSTALLER_ACCEPTED,
      InstallationStatus.FOR_INSTALLER_ACCEPTANCE,
    ].includes(installationStatus);

  const shouldCacheForTester =
    userRole === ActivityDifferentiator.CMSN &&
    [
      InstallationStatus.FOR_TESTER_ACCEPTANCE,
      InstallationStatus.TESTER_ACCEPTED,
    ].includes(installationStatus);

  const shouldCacheForSupervisor =
    userRole === ActivityDifferentiator.SPV &&
    [
      InstallationStatus.FOR_FINAL_INSPECTION,
      InstallationStatus.SEB_ACCEPTED,
      InstallationStatus.SEB_REJECTED,
    ].includes(installationStatus);

  const shouldCacheForServiceEngineer =
    userRole === ActivityDifferentiator.SEEN &&
    installationStatus === InstallationStatus.FOR_SEB_ACCEPTANCE;

  return (
    shouldCacheForInstaller ||
    shouldCacheForTester ||
    shouldCacheForSupervisor ||
    shouldCacheForServiceEngineer
  );
};

const NetworkInstallation = (props: NetworkInstallationProp): JSX.Element => {
  const { redirectToReferrerUrl } = props;
  const { networkNumber: networkNumberParam } = useParams() as QueryParams;
  const { networkNumber, updateNetworkNumber, installationData, updateInstallationData } =
    useContext(Context);
  const { dispatch } = useContext(InstallationContext);
  const [employeeId] = useGetUserData();
  const [userRole] = useGetCurrentUserRole();
  const [getTokenFunction] = useGetToken();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [progressPercentage, setProgressPercentage] = useState<number>(0);
  const location = useLocation();
  const [isSubcontractor] = useIfSubcontractor();

  const matchedRoute = SUPPORTED_URLs.find((url: string) =>
    matchPath(location.pathname, { path: url, exact: true })
  );

  const isQuestionnaireUrl =
    matchedRoute &&
    [
      NetworkInstallationPaths.EXECUTION,
      SubcontractorNetworkInstallationPaths.EXECUTION,
    ].includes(matchedRoute);

  const history = useHistory();
  const searchQuery = new URLSearchParams(location.search);
  const questionSetIdParam = searchQuery.get('questionSetId');

  const isUserSupervisor =
    userRole === ActivityDifferentiator.SEEN || userRole === ActivityDifferentiator.SPV;

  const installationStatus =
    installationData && installationData.status
      ? installationData.status
      : installationStatuses[0];

  const hasWorkflowPassedForFinalInspection =
    getInstallationStatusIndex(installationStatus) >=
    getInstallationStatusIndex(InstallationStatus.FOR_FINAL_INSPECTION);

  const sebInspectionDone =
    getInstallationStatusIndex(installationStatus) >
    getInstallationStatusIndex(InstallationStatus.FOR_SEB_ACCEPTANCE);

  const isEagerSupervisor =
    userRole === ActivityDifferentiator.SPV && !hasWorkflowPassedForFinalInspection;

  const { t, i18n } = useTranslation();

  type InstallationDataToUse = {
    answersToUse?: AnswerSet[];
    questionsToUse?: QuestionSet[];
    questionSetSequenceToUse?: string[] | null;
  };
  // the follow variables is need to pick the correct questions for different roles
  const { answersToUse, questionsToUse, questionSetSequenceToUse } =
    ((): InstallationDataToUse => {
      switch (userRole) {
        case ActivityDifferentiator.INST:
          return {
            answersToUse: installationData?.installerAnswers,
            questionsToUse: installationData?.installerQuestions,
            questionSetSequenceToUse: installationData?.installerQuestionSetSequence,
          };
        case ActivityDifferentiator.CMSN:
          return {
            answersToUse: installationData?.testerAnswers,
            questionsToUse: installationData?.testerQuestions,
            questionSetSequenceToUse: installationData?.testerQuestionSetSequence,
          };
        case ActivityDifferentiator.SPV:
          return {
            answersToUse: installationData?.testerAnswers,
            /*
              In some front line, there is a special case that NEB supervisor first see
              one sequence of question sets after the handover from SEB supervisor, the NEB supervisor must
              see what the SEB supervisor / service engineer sees which could include some extra (local) question sets
            */
            questionsToUse: sebInspectionDone
              ? installationData?.sebSupervisorQuestions
              : installationData?.nebSupervisorQuestions,
            questionSetSequenceToUse: sebInspectionDone
              ? installationData?.sebSupervisorQuestionSetSequence
              : installationData?.nebSupervisorQuestionSetSequence,
          };
        case ActivityDifferentiator.SEEN:
          return {
            answersToUse: installationData?.testerAnswers,
            questionsToUse: installationData?.sebSupervisorQuestions,
            questionSetSequenceToUse: installationData?.sebSupervisorQuestionSetSequence,
          };
        default:
          return {};
      }
    })();

  /* this is used to set the network number on the first time user landed on the app */
  useEffect(() => {
    updateNetworkNumber(networkNumberParam);
  }, [networkNumberParam]);

  /* this is used to fetch and set the Installation data on the first time user landed on the app */
  /* TODO: this caching part could be on it's own hook/helper function */
  useEffect(() => {
    //helper functions for caching purpose
    const cacheInstallationDataAndImages = async (
      accessToken: string,
      installation: Installation,
      userRole: ActivityDifferentiator
    ): Promise<void> => {
      await cacheNetworkWithInstallationData(
        installation,
        networkNumber,
        accessToken,
        userRole
      );

      const questionSetToCacheImages: QuestionSet[] = (() => {
        switch (userRole) {
          case ActivityDifferentiator.INST:
            return installation.installerQuestions;
          case ActivityDifferentiator.CMSN:
            return installation.testerQuestions;
          case ActivityDifferentiator.SPV:
            return installation.nebSupervisorQuestions;
          case ActivityDifferentiator.SEEN:
            return installation.sebSupervisorQuestions;
          default:
            throw Error(
              'Cannot figure out which questions set to use for image caching purpose'
            );
        }
      })();

      const splitQuestions = chunk(questionSetToCacheImages, 2);
      const progressForEachChunk = 70 / splitQuestions.length;
      for (let index = 0; index < splitQuestions.length; index++) {
        await extractImageLink(splitQuestions[index], accessToken);
        setProgressPercentage((progress) => progress + progressForEachChunk);
      }
    };

    if (networkNumber) {
      const fetchInstallationData = async () => {
        try {
          if (!installationData) {
            const accessToken = await getTokenFunction();
            setProgressPercentage(5);

            const data: Installation | null = await get(
              `v1/installations/${networkNumber}`,
              accessToken
            );

            if (data) {
              setProgressPercentage(20);

              const networkNumberKey = generateIndexedDBKey(
                networkNumber,
                storedIndexedDBObjectType.NETWORK
              );
              const isNetworkDataInIndexDb = await getIndexedDBObject(networkNumberKey);
              const role = isSubcontractor
                ? getSubcontractorUserRole(networkNumber)
                : getKoneEmployeeUserRole({ installationData: data, employeeId });
              const isCachingNeeded = getIfCachingNeeded(data.status, role);
              /* Caching start here....Only cache if the data is not cached yet
                  aka can't find network key in indexedDB  */

              const deviationDataKey = generateIndexedDBKey(
                networkNumber,
                storedIndexedDBObjectType.DEVIATIONS
              );
              const isDeviationsInIndexedDB = await getIndexedDBObject(deviationDataKey);
              const shouldCacheDeviationsIntoIndexDb =
                !isDeviationsInIndexedDB && isCachingNeeded;

              const fetchedDeviations = await getDeviationsData(
                accessToken,
                networkNumber
              );
              dispatch({
                type: InstallationActionName.SET_DEVIATIONS,
                deviations: fetchedDeviations,
              });

              if (shouldCacheDeviationsIntoIndexDb) {
                await cacheDeviationsData(networkNumber, fetchedDeviations);
              }

              const shouldStartCachingIntoIndexDb =
                !isNetworkDataInIndexDb && isCachingNeeded;
              if (shouldStartCachingIntoIndexDb) {
                if (role) {
                  await Promise.all([
                    getEmployeesDataFromInstallation(accessToken, data),
                    cacheInstallationDataAndImages(accessToken, data, role),
                  ]);
                } else {
                  throw Error(
                    'Cannot fingure out user role in order to create syncpoint'
                  );
                }
              }

              const installationDataWithHandover = addHandoverStep(data);
              updateInstallationData(installationDataWithHandover);
            }
          }
        } catch (e) {
          console.error(
            'Error while fetching installationData, caching the data and adding Handover step',
            e
          );
        } finally {
          setProgressPercentage(100);
        }
      };
      fetchInstallationData();
    }
  }, [networkNumber, installationData]);

  // this is used to move the user to the question next to the furthers answered question
  useEffect(() => {
    if (answersToUse && isQuestionnaireUrl && !questionSetIdParam) {
      if (isUserSupervisor && questionsToUse) {
        history.push(
          `?questionSetId=${questionsToUse[0].questionSetId}&questionNumber=${0}`
        );
        return;
      }
      const nextQuestion = getQuestionNextToFurthestAnsweredQuestion(answersToUse);

      if (nextQuestion) {
        history.push(
          `?questionSetId=${nextQuestion.questionSetId}&questionNumber=${nextQuestion.questionNumberSequence}`
        );
      }
    }
  }, [matchedRoute, answersToUse]);

  // call open endpoint to indicate that SEB opened the link
  useEffect(() => {
    const callOpenEndpoint = async () => {
      const accessToken = await getTokenFunction();
      await post(`v1/installations/${networkNumber}/open`, accessToken);
    };

    if (userRole === ActivityDifferentiator.SEEN) {
      callOpenEndpoint();
    }
  }, [userRole, networkNumber]);

  const handleDialogClick = (shouldOpen: boolean) => {
    if (shouldOpen !== isDialogOpen) setIsDialogOpen(shouldOpen);
  };

  const isStartingInstallation =
    (userRole === ActivityDifferentiator.INST &&
      installationData?.status === InstallationStatus.FOR_INSTALLER_ACCEPTANCE) ||
    (userRole === ActivityDifferentiator.CMSN &&
      installationData?.status === InstallationStatus.FOR_TESTER_ACCEPTANCE);
  const renderView = (matchedRoute?: string, formId?: string | null) => {
    switch (matchedRoute) {
      case SubcontractorNetworkInstallationPaths.DEVIATIONS:
      case NetworkInstallationPaths.DEVIATIONS:
        return <DeviationsView />;

      case NetworkInstallationPaths.PROGRESS:
        if (userRole === ActivityDifferentiator.SPV)
          return (
            <ProgressView installation={installationData} networkNumber={networkNumber} />
          );
        if (userRole !== undefined) window.location.replace('/');
        return null;

      case SubcontractorNetworkInstallationPaths.MYDAY:
      case NetworkInstallationPaths.MYDAY:
        if (
          userRole === ActivityDifferentiator.CMSN ||
          userRole === ActivityDifferentiator.INST ||
          userRole === ActivityDifferentiator.SEEN
        ) {
          const showMyPlan =
            (!installationData?.isModelData && !installationData?.isTacoDataQuest) ||
            installationData?.installerQuestionSetSequence.length === 2;
          return <InstallationTaskView showMyPlan={showMyPlan} />;
        }
        if (userRole !== undefined) window.location.replace('/');
        return null;

      case NetworkInstallationPaths.STARTING:
      case SubcontractorNetworkInstallationPaths.STARTING:
        if (installationData && !isStartingInstallation) {
          window.location.replace('/');
          return null;
        }
        return <InstallationStarting />;
      case SubcontractorNetworkInstallationPaths.PREINSTALLCHECKLIST:
      case NetworkInstallationPaths.PREINSTALLCHECKLIST:
        if (installationData && !isStartingInstallation) {
          window.location.replace('/');
          return null;
        }
        return <PreInstallationChecklist />;
      case NetworkInstallationPaths.EXECUTION:
      case SubcontractorNetworkInstallationPaths.EXECUTION:
        if (isStartingInstallation) {
          window.location.replace('/');
          return null;
        }

        if (isEagerSupervisor) {
          return (
            <Empty displayIcon={false} message={t('qdPage.notReadyForFinalInspection')} />
          );
        }

        switch (formId) {
          case FormId.QDPLUS:
            return (
              <QuestionsSequence questionSets={questionsToUse} answers={answersToUse} />
            );
          case FormId.NEBSEB:
          case FormId.SEBLOC:
            return <QuestionsList questionSets={questionsToUse} answers={answersToUse} />;
          default:
            return null;
        }
    }
  };

  const questionSetInfo = installationData?.questionSetInfo?.find(
    (qSet) => qSet.setId === questionSetIdParam
  );
  const onChangeNavigation = (step: NavigationStep) => {
    const { id } = step;
    history.push(`?questionSetId=${id}&questionNumber=0`);
  };
  let currentQuestionsetIndex = 0;

  // The state management of stepper is done based on route
  const questionSetIndex = questionsToUse?.findIndex(
    (qSet) => qSet.questionSetId === questionSetIdParam
  );
  currentQuestionsetIndex =
    questionSetIndex && questionSetIndex !== -1 ? questionSetIndex : 0;

  const navigationSteps = questionSetSequenceToUse
    ?.map((questionSetId) =>
      getQuestionSetDescription(questionSetId, installationData?.questionSetInfo, i18n)
    )
    .map(([id, displayText]) => ({ id, displayText }));

  const navTitle = installationData?.equipmentNumber
    ? [
        networkNumber,
        `${t('installationCard.equipmentNumber')} ${installationData?.equipmentNumber}`,
      ]
    : networkNumber;

  return (
    <>
      <SubHeader
        title={navTitle}
        handleInfoClick={() => setIsDialogOpen(true)}
        handleGoBackClick={isSubcontractor ? undefined : redirectToReferrerUrl}
      />
      <CustomizedProgressBar progress={progressPercentage} />
      {isDialogOpen && (
        <NetworkDetailsModal handleCloseIconClick={() => handleDialogClick(false)} />
      )}
      {isQuestionnaireUrl && (
        <NavigationStepper
          activeStep={currentQuestionsetIndex}
          navigationSteps={navigationSteps}
          onClickStep={onChangeNavigation}
          questions={questionsToUse}
          answers={answersToUse}
        />
      )}
      {renderView(matchedRoute, questionSetInfo?.formId)}
      <FloatingSyncButton />
    </>
  );
};

export default NetworkInstallation;
