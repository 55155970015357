import { useContext } from 'react';
import Context from '../context/index';
import { ActivityDifferentiator } from '../schemas';
import {
  getKoneEmployeeUserRole,
  getSubcontractorUserRole,
} from '../helpers/getUserRole';
import { useIfSubcontractor } from './useIfSubcontractor';

export const useGetCurrentUserRole = (): [undefined | ActivityDifferentiator] => {
  const { installationData, authState, networkNumber } = useContext(Context);

  const [isSubcontractor] = useIfSubcontractor();

  const { graph } = authState || {};

  const employeeId = graph?.profile.employeeId;

  const role = isSubcontractor
    ? getSubcontractorUserRole(networkNumber)
    : getKoneEmployeeUserRole({
        installationData: installationData ?? undefined,
        employeeId,
      });
  return [role];
};
